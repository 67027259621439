@media screen and (width > 680px) {
    .header-div{
        background-color: #004F87;
        display: flex;
        height: 50px;
    }
    .footer{
        background-color: #004F87;
    }
    .white-footer{
        display: none;
    }
  }
  
  @media screen and (width < 680px) {
    .header-div{
        display: none;
    }
    .footer{
        /* display: none; */

        background-color: #004F87;
    }
    .white-footer{
        display: block;
    }
}

.Header-Links{
    display: flex;
    justify-content: center;
    align-items: center;
}

.angle-div {
    position: relative;
    width: 20px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    transform: rotate(155deg);
    background-color: aliceblue;
  }

.header-contant{
    position: absolute;
    left: 188px;
    width: calc(100% - 190px);
    height: 35px;
    background-color: #004779;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-support-text {
    font-family: "Gellix";
    font-size: 14px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.header-location-text{
    font-family: "Gellix";
    font-size: 15px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    color: #ffffff;
}

.header-email-text {
    font-family: "Roboto";
    font-size: 15px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    color: #ffffff;
}

/* ============================================= Navbar ====================================================== */

.nav_link {
    color: white;
    font-family: "Gellix";
    font-size: 16px;
    font-weight: 700;
    line-height: 29px;
    text-decoration: none;
}

.nav_link:hover {
    color: #FFBF43;
    font-size: 16px;
    font-weight: bold;
}

.active-link {
    color: #FFBF43;
    font-size: 16px;
    /* padding: 8px; */
    font-weight: bold;
}

.active-link:hover {
    font-weight: bold;
}


.dropdown {
    position: relative;
    display: inline-block;
  }

.offer-dropdown {
    position: relative;
    display: inline-block;
  }

.advertisement-dropdown{
    position: relative;
    display: inline-block;
}
  
  .dropdown-content {
    display: none;
    position: absolute;
    top: 50px;
    background-color: #ECECEC;
    min-width: 160px;
    /* overflow: auto; */
    /* overflow-y: visible; */
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    z-index: 1;
  }

.sub-dropdown-conten {
    display: none;
    position: absolute;
    top: 53%;
    left: 105%;
    background-color: #ECECEC;
    min-width: 160px;
    /* overflow: auto; */
    overflow-x: hidden;
  overflow-y: auto;
  height: 250px;
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    z-index: 1;
}

.advertisement-dropdown-conten{
    display: none;
    position: absolute;
    top: 75%;
    left: 105%;
    background-color: #ECECEC;
    min-width: 160px;
    overflow-x: hidden;
  overflow-y: auto;
  /* height: 160px; */
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    z-index: 1;
}
      /* ========================================================== */

      .sub-dropdown-conten::-webkit-scrollbar, .advertisement-dropdown-conten::-webkit-scrollbar {
        width: 8px; /* Width of the scrollbar */
        
      }
      
      /* Change the color of the scrollbar thumb */
      .sub-dropdown-conten::-webkit-scrollbar-thumb,  .advertisement-dropdown-conten::-webkit-scrollbar-thumb{
        background-color: #004779d1; /* Color of the thumb */
        border-radius: 6px; /* Rounded corners */
      }
      
      /* Change the color on hover */
      .sub-dropdown-conten::-webkit-scrollbar-thumb:hover,  .advertisement-dropdown-conten::-webkit-scrollbar-thumb:hover{
        background-color: #004779;
      }
      
      /* Change the color when actively being dragged */
      .sub-dropdown-conten::-webkit-scrollbar-thumb:active,  .advertisement-dropdown-conten::-webkit-scrollbar-thumb:active{
        background-color: #0565aa;
      }
      
      /* Change the color of the scrollbar track */
      .sub-dropdown-conten::-webkit-scrollbar-track,  .advertisement-dropdown-conten::-webkit-scrollbar-track{
        background-color: #f0f0f0; /* Color of the track */
        text-align: center;
      }
      
      /* Change the color of the scrollbar corner */
      .sub-dropdown-conten::-webkit-scrollbar-corner,  .advertisement-dropdown-conten:-webkit-scrollbar-corner{
        background-color: #f0f0f0; /* Color of the corner */
      }

.subnavbar {
    background: #D6D6D6;
    color: #6a6a6a;
    font-family: "Gellix";
    padding: 5px;
    font-size: 16px;
    text-decoration: none;
    margin: 3px 0px;
    display: block;
    width: 200px;
}

.subnavbar:hover {
    color: rgb(0, 80, 157, 1);
    font-size: 16px;
    font-weight: bold;
}


.active-subnavbar {
    color: rgb(0, 80, 157, 1);
    font-size: 16px;
    /* padding: 8px; */
    font-weight: bold;
}

.active-subnavbar:hover {
    font-weight: bold;
}


/* ================================================== Footer ======================================================= */

.footer-title {
    /* font-family: "Gellix"; */
    font-size: 40px;
    font-weight: 700;
    /* line-height: 120px; */
    letter-spacing: 0em;
    text-align: left;
    font-family: "Cormorant";
    color: #FFBF43;
}

.footer-heading{
    font-family: "Gellix";
    font-size: 20px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
}

.footer-subHeading{
    font-family: "Gellix";
    font-size: 16px;
    font-style: italic;
    font-weight: 800;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
}

.footer-number{
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
}

.footer-address {
    font-family: "Gellix";
    font-size: 15px;
    /* font-weight: 600; */
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
}

.footer-btn {
    font-family: "Gellix";
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    background-color: #292C39;
    border-radius: 5px;
    color: #ffffff;
}

.footer-btn:hover{
    background-color: #1f222c;
}


.agree-text{
    font-family: "Gellix";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.footer-copyright{
    font-family: "Gellix";
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
}

