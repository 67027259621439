.career-title{
    font-family: "Gellix";
    /* font-size: 64px; */
    font-weight: 700;
    line-height: 68px;
    text-align: left;
    color: #004779;
}

.career-heading{
    font-family: "Gellix";
    /* font-size: 64px; */
    font-weight: bolder;
    line-height: 54px;
    text-align: left;
}

.career-subheading{
    font-family: "Gellix";
    /* font-size: 42px; */
    font-weight: 500;
    line-height: 30px;
    /* text-align: center; */
}

.career-text{
    font-family: "Gellix";
    /* font-size: 22px; */
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #0F1B24;
}

.career-transparent1 {
    background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url("../../assets/Transparent/Career Transperent bg 1.png");
    background-size: auto; 
}