.home-heading{
    font-family: "Gellix";
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: left;
    color: #004779;
}

.certified{
    background-color: #004F87;
}

.home-transparent1 {
    background-image: linear-gradient(rgba(0, 79, 135, 0.9), rgba(0, 79, 135, 0.9)), url("../../assets/Transparent/Home Transparent 1.png");
    background-size: cover; 
}

.home-transparent2 {
    background-image: linear-gradient(rgba(0, 79, 135, 0.5), rgba(0, 79, 135, 0.5)), url("../../assets/Transparent/Home Transparent 2.png");
    background-size: auto; 
}

.home-transparent3 {
    background-image: linear-gradient(rgba(255, 255, 255), rgba(255, 255, 255)), url("../../assets/Transparent/Home Page Brand  Transparent 3.png");
    background-size: auto; 
}

.certified-left-margin{
    color: #FFBF43;
    border-left: 6px solid #FFBF43;
    color: #ffffff;
    margin: 15px 0px;
    padding: 0px 20px;
}

.btn-gold {
    font-family: "Gellix";
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    background-color: #FFBF43;
    border-radius: 5px;
    color: #ffffff;
    border-radius: 0px;
    padding: 10px;
    position: relative;
}

.btn-gold:hover{
    background-color: #dba63a;
    color: #ffffff;
}

.btn-square {
    background-color: #ffffff;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 4px;
    height: 4px;
}

.btn-blue {
    font-family: "Gellix";
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    background-color: #004F87;
    border-radius: 5px;
    color: #ffffff;
    border-radius: 0px;
    padding: 10px;
    position: relative;
}

.btn-blue:hover{
    background-color: #03497b;
    color: #ffffff;
}


.btn-gray {
    font-family: "Gellix";
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    background-color: #D6D6D6;
    border-radius: 5px;
    color: #333333;
    border-radius: 0px;
    padding: 10px;
    position: relative;
}

.btn-gray:hover{
    background-color: #c5c5c5;
    color: #333333;
}

@media screen and (width > 680px) {
    .home-subheading-div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .home-subheading-white-box{
        background-color: #ECECEC;
        width: 40%;
        padding: 0px 30px;
    }
    .width-50{
        width: 50%;
    }
  }
  
  @media screen and (width < 680px) {
    .home-subheading-div{
        display: block;
    }
    .home-subheading-white-box{
        background-color: #ECECEC;
        width: 100%;
        padding: 15px 30px;
    }
    .width-50{
        width: 100%;
    }
}



.home-subheading-white-title{
    font-family: "Gellix";
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    color: #303030;
    
}

.home-subheading-white-text{
    font-family: "Gellix";
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    color: #808080;
}

.home-subheading-text-box{
    width: 70%;
}

.home-subheading-text-box-heading{
    font-family: "DM Sans";
    font-weight: 700;
    line-height: 63px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.home-subheading-text-box-subheading{
    font-family: "Gellix";
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.home-subheading-text-number{
    font-family: "Gellix";
    font-weight: 700;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #ffffff;
}

.home-subheading-text-number-text{
    font-family: "Gellix";
    font-style: italic;
    font-weight: 800;
    line-height: 29px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: #ffffff;
}


.home-about-message{
    font-family: "Gellix";
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #004F87;
}

.home-whatdowe{
    font-family: "DM Sans";
    font-weight: 700;
    line-height: 61px;
    letter-spacing: 0em;
    text-align: left;
    color: #004F87;
}

.home-do{
    color: #004F87;
    font-family: "DM Sans";
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
}

.home-whatwedo-card-header{
    font-family: "Poppins";
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #004F87;
}

.home-whatwedo-card-text{
    font-family: "Poppins";
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #757575;
}

.home-whatdowe-card-subtext{
    font-family: "Gellix";
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #004F87;
}

.product-box {
    /* height: 450px; */
    overflow: hidden;
    border: #d7d7d7 solid 1px;
    margin-right: 8px;
    background-clip: padding-box;
    background-color: #d7d7d7;
    position: relative;
    padding-bottom: 20px;
}

.product-box-text {
    font-family: "Gellix";
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
}

.product-box-readmore{
    position: absolute;
    left: 15px;
    bottom: 0px;
}

.employee-rating {
    font-family: "Cormorant";
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #151515;
}

.employee-text {
    font-family: "Gellix";
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    color: #151515;
}

.client-title {
    font-family: "DM Sans";
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
}

.client-subtitle {
    font-family: "Gellix";
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    color: #dba63a;
}

.client-heading {
    font-family: "Gellix";
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    color: #004779;
}

.client-box-title{
    font-family: "Gellix";
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: left;
    color: #696969;
}

.client-box-text{
    font-family: "Gellix";
    font-style: italic;
    font-weight: 800;
    line-height: 29px;
    letter-spacing: 0em;
    color: #BABABA;

}

.client-box-col{
    width: 250px;
    height: 126px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #0000001A
}

.ourValue-heading{
    font-family: "Gellix";
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0em;
    color: #ffffff;
}

.ourValue-text{
    font-family: "Gellix";
    font-style: italic;
    font-weight: 800;
    line-height: 29px;
    letter-spacing: 0em;
    color: #ffffff;
}

.home-news-title{
    font-family: "Gellix";
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
}

.profile-card {
    background-color: #f5f5f5;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}

.profile-card::before {
    content: "";
    position: absolute;
    bottom: -33px; /* Adjust the distance of the triangle from the bottom */
    left: 35%; /* Position the triangle in the middle */
    transform: translateX(-50%) skewX(-45deg); /* Skew and position the triangle */
    width: 0;
    height: 0;
    border-left: 35px solid transparent; /* Size and color of the left border */
    border-right: 35px solid transparent; /* Size and color of the right border */
    border-top: 35px solid #f5f5f5; /* Size and color of the top border (to create the triangle shape) */
}

.profile-circle {
    position: absolute;
    bottom: -100px;
    left: 1px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 auto 20px;
}

.profile-circle::before {
    content: "";
    position: absolute;
    width: 120px; /* Adjust the size of the outer circle */
    height: 120px; /* Adjust the size of the outer circle */
    border: 10px solid transparent; /* Set border size and make it transparent */
    border-radius: 50%; /* Create a circle */
    top: 50%; /* Position the circle at the center */
    left: 50%; /* Position the circle at the center */
    transform: translate(-50%, -50%); /* Center the circle */
}

  .profile-name {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }

  .profile-company {
    text-align: center;
    color: #666;
    font-weight: 700;
  }

  .image-container-img{
    transition: transform 0.3s ease; /* Define the transition property */
  }

  .image-container-img:hover {
    transform: scale(1.1);
}