/* Gallery container: grid layout for responsive design */
.gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive grid */
    gap: 20px; /* Space between items */
    padding: 20px;
    justify-items: center; /* Center items in each grid cell */
    margin: 20px 60px;
  }
  
 /* Individual gallery item with fixed size */
.gallery-item {
    position: relative;
    width: 200px;  /* Fixed width */
    height: 200px; /* Fixed height */
    overflow: hidden;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Image inside gallery item */
  .gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire div without distortion */
    object-position: center; /* Centers the image in the container */
    border-radius: 10px; /* Matches the border radius of the container */
    transition: transform 0.3s ease;
  }
  
  /* Hover effect for images: scale and shadow */
  .gallery-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
  }
  
  .gallery-item:hover img {
    transform: scale(1.1); /* Slight zoom on hover */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .gallery-container {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust for tablet */
    }
    
    .gallery-item {
      width: 150px;
      height: 150px;
    }
  }
  
  @media (max-width: 480px) {
    .gallery-container {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Adjust for mobile */
    }
    
    .gallery-item {
      width: 100px;
      height: 100px;
    }
  }