.blog-div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.blog-title{
    font-family: "Gellix";
    /* font-size: 54px; */
    font-weight: bolder;
    line-height: 65px;
    letter-spacing: 0em;
    text-align: center;
    color: #004F87;
}

.blog-small-text{
    font-family: "Gellix";
    /* font-size: 18px; */
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #004F87;
}

.blog-heading{
    font-family: "DM Sans";
    /* font-size: 48px; */
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
}

.blog-subheading{
    font-family: "Gellix";
    /* font-size: 24px; */
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
}

.blog-text{
    font-family: "Poppins";
    /* font-size: 16px; */
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #757575;
}

.blog-box{
    border: 1px solid #0F1B241A;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
    background: white;
}

/* scroll bar */

  .modal-content::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }
  
  /* Change the color of the scrollbar thumb */
  .modal-content::-webkit-scrollbar-thumb{
    background-color: #004779d1; /* Color of the thumb */
    border-radius: inherit; /* Rounded corners */
  }
  
  /* Change the color on hover */
  .modal-content::-webkit-scrollbar-thumb:hover{
    background-color: #004779;
    border-radius: inherit;
  }
  
  /* Change the color when actively being dragged */
  .modal-content::-webkit-scrollbar-thumb:active{
    background-color: #0565aa;
    border-radius: inherit;
  }
  
  /* Change the color of the scrollbar track */
  .modal-content::-webkit-scrollbar-track{
    background-color: #f0f0f0; /* Color of the track */
    text-align: center;
    border-bottom-right-radius: inherit;
    border-top-right-radius: inherit;
  }
  
  /* Change the color of the scrollbar corner */
  .modal-content:-webkit-scrollbar-corner{
    background-color: #f0f0f0; /* Color of the corner */
  }