.no-data{
    min-height: 90vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(217, 217, 217, 0), rgba(217, 217, 217, 0)), url("../../assets/Transparent/Home Page Brand  Transparent 3.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.invertors-title {
    font-family: "Gellix";
    /* font-size: 54px; */
    font-weight: 700;
    line-height: 64.81px;
    text-align: left;
    color: #333333;
}

.invertors-heading {
    font-family: "Gellix";
    /* font-size: 48px; */
    font-weight: 700;
    /* line-height: 58px; */
    text-align: center;
    color: #333333;
}

.invertors-text {
    font-family: Roboto;
    /* font-size: 16px; */
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #666666;
}

.invertors-table {
    border-collapse: collapse;
    width: 100%;
}

.investor-thead{
    background: #004779;
    color:#ffffff;
    font-weight: bold;
    border-radius: 50%;
    /* border: 3px solid red; */
}

.investor-thead th:first-child{
    border-radius:31px 0 0 31px;
  }
  
  .investor-thead th:last-child{
    border-radius:0 31px 31px 0;
  }

.investor-thead th {
    padding: 14px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.investor-tbody{
    margin-top: 10px;
}

.investor-tbody:before {
    content: "@";
    display: block;
    line-height: 1em;
    color: transparent;
    background: #ECECEC;
}

.investor-tbody td {
    padding: 14px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

.investor-tbody tr:hover {
    background-color:#f2f2f2;
}

.investor-navbar {
    font-family: "Gellix";
    padding: 5px;
    text-decoration: none;
    color: #144678;
}

.investor-navbar:hover {
    color: #FFBF43;
    font-size: 16px;
    font-weight: bold;
}


.active-investor-navbar {
    color: #FFBF43;
    font-size: 16px;
    /* padding: 8px; */
    font-weight: bold;
}

.active-investor-navbar:hover {
    font-weight: bold;
}

.investorList-div {
    background: linear-gradient(
    to bottom,
    #ECECEC 0%,
    #ECECEC  50%,
    #004F87 50%,
    #004F87 100%
  );
}

.invertorsList-text {
    font-family: "Gellix";
    /* font-size: 24px; */
    font-weight: 700;
    /* line-height: 34px; */
    text-align: center;
    color: inherit;
}

.invertorsList-heading {
    font-family: "Gellix";
    /* font-size: 48px; */
    font-weight: 700;
    /* line-height: 58px; */
    text-align: center;
    color: #144678;
}

.invertorsList-number {
    font-family: "Gellix";
    /* font-size: 48px; */
    font-weight: 700;
    /* line-height: 58px; */
    text-align: left;
    /* color: #707070; */
    color: inherit;
}

.investorList-white-box{
    border-radius: 5px;
    padding: 15px;
    opacity: 0px;
    background: #F6F6F6;
}