body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ECECEC;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Gellix';
  src: url("./assets/Fonts/Gellix-Regular.ttf");
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant&family=DM+Sans&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


@media screen and (width > 520px) {
  .title {
    font-size: 30px;
  }
  
  .heading {
    font-size: 24px;
  }
  
  .subheading {
    font-size: 20px;
  }
  
  .text {
    font-size: 16px;
  }
  
  .small-text {
    font-size: 14px;
  }
}

@media screen and (width < 520px) {
  .title {
    font-size: 24px;
  }
  
  .heading {
    font-size: 20px;
  }
  
  .subheading {
    font-size: 18px;
  }
  
  .text {
    font-size: 16px;
  }
  
  .small-text {
    font-size: 14px;
  }
}