.product-title{
    font-family: "Gellix";
    /* font-size: 64px; */
    font-weight: 700;
    line-height: 68px;
    text-align: left;
    color: #004779;
}

.product-heading{
    font-family: "Gellix";
    /* font-size: 64px; */
    font-weight: bolder;
    line-height: 54px;
    text-align: left;
}

.product-subheading{
    font-family: "Gellix";
    /* font-size: 42px; */
    font-weight: 500;
    line-height: 30px;
    /* text-align: center; */
}

.product-text{
    font-family: "Gellix";
    /* font-size: 22px; */
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #0F1B24;
}

.product-transparent {
    background-image: linear-gradient(rgba(0, 79, 135, 0.5), rgba(0, 79, 135, 0.5)), url("../../assets/Transparent/Product transparent.png");
    background-size: auto; 
}

.product-scale-box {
    background: #F3F3F3;
    font-family: "Gellix";
    text-align: center;
    padding: 10px;
    margin: 10px;
}

.table-wrapper {
    overflow-x: auto;
    overflow-y: visible;
}
.product-table td {
            border: 3px solid black;
            border-collapse: collapse;
            padding: 8px 16px;
            font-family: "Inter";
            text-align: center;
        }

 hr.black {
    border: 3px solid black;
    border-radius: 5px;
    opacity: 1;
 }

 .brand-slider{
    width: 100%;
    /* height: 200px; */
 }