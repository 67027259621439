

.contact-title{
    font-family: "Gellix";
    /* font-size: 48px; */
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: left;
}

.contact-heading{
    font-family: "Gellix";
    /* font-size: 30px; */
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #004779;
}

.contact-subheading{
    font-family: "Gellix";
    /* font-size: 24px; */
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #004779;
}

.contact-text{
    font-family: "Gellix";
    /* font-size: 16px; */
    font-weight: 300;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
}