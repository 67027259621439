.about-page-heading {
    font-family: "Gellix";
    font-weight: 700;
    line-height: 65px;
    letter-spacing: 0em;
    text-align: start;
    color: #333333;
}

.about-page-content-heading{
    font-family: "Gellix";
    font-weight: 700;
    line-height: 61px;
    letter-spacing: 0em;
    color: #ffffff;
}

.about-page-content-text{
    font-family: "Gellix";
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0em;
    color: #ffffff;
}

.Manufacturing{
    font-family: "Gellix";
    font-weight: 700;
    line-height: 101px;
    letter-spacing: 0em;
    color: #004779;
}

.Manufacturing-title{
    font-family: "Gellix";
    font-weight: 400;
    line-height: 53px;
    letter-spacing: 0em;
    color: #004779;
}

.Manufacturing-circle{
    width: 50px;
    height: 50px;
    background-color: #004779;
    border-radius: 50%;
}

.Manufacturing-number{
    font-family: "Lora";
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0em;
    color: #333333;
}

.Manufacturing-number-text{
    font-family: "Roboto";
    font-weight: 400;
    line-height: 0.2;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
}

.photo-rectangle{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 365px;
    height: 373px;
    background-color: #D7D7D7;
    border: 1px solid #FFFFFF1A;
}

.person-silder-button {
    position: relative;
}

.person-slider .slick-next{
    display: none;
    right: 15px;
    z-index: 999;
}

.person-slider .slick-next:before{
    display: none;
    color: #ff0000;
}
.person-slider .slick-prev {
    display: none;
    left: 15px;
    z-index: 999;
}
.person-slider .slick-prev:before{
    display: none;
    color: #fa0000;
}

.project-div{
    background: #D9D9D9;
    padding: 25px 10px;
}

.project-title{
    font-family: "Gellix";
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
}

.project-text{
    font-family: "Gellix";
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.project-rectangle-div-box{
    width: 300px;
    height: 349px;
    background-color: #f5f5f5;
}

.project-rectangle-div-insidebox{
    width: 283px;
    height: 272px;
    background-color: #d9d9d9;
    margin: 10px auto;
}

.project-rectangle-div-text{
    font-family: "Gellix";
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: black;
}

.about-content-col-img{
    position: relative;
}

    .about-content-col-img1{
        position: absolute;
        top: 1px;
        left: 1px;
        width: 400px;
        height: 300px;
        background-color: #D9D9D9;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .about-content-col-img2{
        position: absolute;
        top: 200px;
        left: 200px;
        width: 400px;
        height: 300px;
        background-color: #D9D9D9;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  
    .about-transparent1 {
        background-image: linear-gradient(rgba(217, 217, 217, 0.9), rgba(217, 217, 217, 0.9)), url("../../assets/Transparent/about transparent 1.png");
        background-repeat: no-repeat;
        background-size: cover;
    }


    .person-transparent1 {
        background-image: linear-gradient(rgba(217, 217, 217, 0), rgba(217, 217, 217, 0)), url("../../assets/Transparent/Home Page Brand  Transparent 3.png");
        background-repeat: no-repeat;
        background-size: cover;
    }