.dealer-title{
    font-family: "Gellix";
    /* font-size: 64px; */
    font-weight: 900;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    color: #004779;
}

.dealer-heading{
    font-family: "Gellix";
    /* font-size: 44px; */
    font-weight: 900;
    line-height: 49px;
    letter-spacing: 0em;
    text-align: left;
    color: #004779;
}

.dealer-text{
    font-family: "Gellix";
    /* font-size: 22px; */
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #0F1B24;
}

.dealer-inputs {
    border: 5px solid #004779;
    background: #004779;
    border-radius: 44px;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #CACACA;
    font-size: 16px;
    line-height: 0;
}

.dealer-inputs:hover {
    cursor: pointer;
}
  
.dealer-inputs:focus {
    outline: none;
    border-color: #004779;
}

.dealer-inputs option {
    background-color: white;
    color: black;
  }

.dealer-inputs::placeholder{
    color: #CACACA;
    font-size: 16px;
}

.dealer-card{
    border: 1px solid #CACACA;
    background-color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    padding: 10px;
    text-wrap: wrap;
}

/* loader.css */
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader::before {
    content: '';
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 5px solid #333;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  